<template>
  <layout :dividers="false">
    <template slot="header">
      {{ title }}
    </template>
    <v-form>
      <form-date-picker v-model="ifrom" label="From" />
      <form-date-picker v-model="ito" label="To" />
      <form-switcher
        v-if="showDataShape"
        v-model="shape"
        title="Download wide data"
      />
      <v-expand-transition>
        <v-alert v-if="error" dense color="red" type="error">
          Error during download history, please, try later
        </v-alert>
      </v-expand-transition>
    </v-form>
    <template slot="footer">
      <v-spacer />
      <v-btn text @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn :loading="loading" color="primary" text @click="submit">
        {{ submitText }}
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref } from '@vue/composition-api';
export default {
  name: 'HistoryDateRangePopup',
  components: {
    Layout
  },
  props: {
    to: {
      type: [String, Date],
      default: () => new Date()
    },
    from: {
      type: [String, Date],
      default: () => new Date()
    },
    title: {
      type: String,
      default: 'Download history'
    },
    submitText: {
      type: String,
      default: 'Download'
    },
    onSubmit: {
      type: Function,
      default: () => ({})
    },
    showDataShape: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const error = ref(false);
    const ifrom = ref(new Date().toISOString().substr(0, 10));

    if (props.from) {
      ifrom.value = props.from.toISOString().substr(0, 10);
    }
    const ito = ref(new Date().toISOString().substr(0, 10));

    if (props.to) {
      ito.value = props.to.toISOString().substr(0, 10);
    }

    const dataShapes = {
      true: 'wide',
      false: 'long'
    };

    const shape = ref(true);

    const submit = async () => {
      try {
        error.value = false;
        loading.value = true;
        await props.onSubmit({
          from: ifrom.value,
          to: ito.value,
          shape: dataShapes[shape.value]
        });
        emit('close');
      } catch {
        error.value = true;
      } finally {
        loading.value = false;
      }
    };

    return {
      ifrom,
      ito,
      dataShapes,
      shape,
      submit,
      error,
      loading
    };
  }
};
</script>

<style lang="sass"></style>
